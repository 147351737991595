<template>
  <div class="campaigns-wrapper" id="divCampaignListWrapper" @click="closeDrapdown">
    <div class="content-row">
      <div class="content-layout-fixer">
        <VueText sizeLevel="11" weightLevel="4" class="campaigns-wrapper-header"
          >Tüm Kampanyalar</VueText
        >
        <ul class="filter-and-search-btn">
          <li @click="toggleSearch" v-if="!searchStatus">
            <VueIcon iconName="IconSearch" color="#b4c2d3"></VueIcon>
          </li>
          <li class="search-wrapper" v-else>
            <input
              class="vue-input"
              name="search"
              placeholder="Kampanya Ara"
              id="search"
              label="Kampanya Ara"
              v-model="search"
              borderType="none"
              @keyup.enter="fetchSearchedText"
            />
            <span class="search-icon" @click="fetchSearchedText">
              <VueIcon iconName="IconSearch" color="#b4c2d3"></VueIcon>
            </span>
          </li>
          <li @click="toggleFilter">
            <VueIcon iconName="IconFilter" color="#b4c2d3"></VueIcon>
          </li>
        </ul>
        <div class="filters-wrapper" id="filterWrapper" v-if="filterDrapdown">
          <div class="filter-row">
            <div class="filter-column" :key="renderKey + 1">
              <VueText sizeLevel="5" weightLevel="4" class="filter-title">Markalar</VueText>
              <div class="filter-list">
                <VueListItem
                  :text="item.Value"
                  @click="selectFilterItem('tags', i)"
                  v-for="(item, i) in filters.tags"
                  :key="'tag_' + i"
                  :icon="getFilterIcon(item.Selected)"
                ></VueListItem>
              </div>
            </div>
            <div class="filter-column" :key="renderKey">
              <VueText sizeLevel="5" weightLevel="4" class="filter-title">Kampanya Türü</VueText>
              <div class="filter-list">
                <VueListItem
                  :text="item.Value"
                  @click="selectFilterItem('campaignTypes', i)"
                  v-for="(item, i) in filters.campaignTypes"
                  :key="'type_' + i"
                  :icon="getFilterIcon(item.Selected)"
                ></VueListItem>
              </div>
            </div>
          </div>
          <div class="filter-row">
            <a class="filter-apply-btn" @click="applyFilter">Filtrele</a>
          </div>
        </div>
      </div>
    </div>
    <div class="content-row">
      <BrandCampaignList :contents="campaigns"></BrandCampaignList>
    </div>
  </div>
</template>
<script>
import VueIcon from '@/components/shared/VueIcon/VueIcon.vue';
import BrandCampaignList from '@/components/brand/BrandCampaignList/BrandCampaignList.vue';
import VueListItem from '@/components/shared/VueListItem/VueListItem.vue';
import { Campaign } from '@/services/Api/index';
import { mapGetters } from 'vuex';
import { ICON_VARIABLES } from '@/constants/component.constants.js';
import { FLEX_JUSTIFIES } from '@/constants/flex.constants.js';
import VueText from '@/components/shared/VueText/VueText.vue';

export default {
  name: 'Campaigns',
  components: {
    VueText,
    BrandCampaignList,
    VueIcon,
    VueListItem,
  },
  data() {
    return {
      searchStatus: false,
      search: null,
      filterDrapdown: false,
      renderKey: 0,
      campaigns: null,
      filters: { contentTypes: [], tags: [] },
      filterModel: { contentTypes: [], tags: [], searchText: '' },
    };
  },
  computed: {
    ...mapGetters('app', ['getFunPageModalStatus', 'getSearchedText']),
    constants() {
      return {
        flexAlignment: FLEX_JUSTIFIES,
      };
    },
    icon() {
      return {
        filter: ICON_VARIABLES.filter,
        checked: ICON_VARIABLES.checked,
        circle: ICON_VARIABLES.circle,
        info: ICON_VARIABLES.info,
      };
    },
  },
  watch: {
    getSearchedText: {
      handler(newVal, oldVal) {
        if (newVal != oldVal) {
          this.filterModel['searchText'] = newVal;
          this.applyFilter();
        }
      },
    },
  },
  beforeMount() {
    Campaign.getCampaignFilters().then(res => {
      if (res && res.data && res.data.Data) {
        this.filters = Object.assign({}, res.data.Data);

        this.filters.tags.forEach((item, key, arr) => {
          let keyValuePair = { Key: item, Value: item.toString() };
          arr[key] = {
            Selected: false,
            ...keyValuePair,
          };
        });
        this.filters.campaignTypes.forEach((item, key, arr) => {
          let keyValuePair = { Key: item.contentTypeId, Value: item.contentName };
          arr[key] = {
            Selected: false,
            ...keyValuePair,
          };
        });

        this.getCampaigns();
      }
    });
  },

  methods: {
    closeDrapdown(e) {
      if (e.target.id === 'divCampaignListWrapper') {
        this.filterDrapdown = false;
        this.searchStatus = false;
      }
    },
    fetchSearchedText() {
      let searchedText = this.search;
      this.$store.dispatch('app/setSearchedText', searchedText);
    },
    toggleSearch() {
      this.searchStatus = !this.searchStatus;
    },
    toggleFilter() {
      this.filterDrapdown = !this.filterDrapdown;
    },
    selectFilterItem(key, index) {
      //item, filterKey, i
      this.renderKey += 1;
      this.filters[key][index].Selected = !this.filters[key][index].Selected;
    },
    getFilterIcon(selected) {
      return selected ? this.icon.checked : this.icon.circle;
    },
    applyFilter() {
      this.filterModel.contentTypes = [];
      this.filterModel.tags = [];

      Object.keys(this.filters).forEach(x => {
        this.filters[x].forEach(item => {
          if (item.Selected) {
            if (x == 'campaignTypes') {
              this.filterModel.contentTypes.push(Number(item.Key));
            } else {
              this.filterModel[x].push(item.Value);
            }
          }
        });
      });

      this.getCampaigns(this.filterModel);
      this.filterDrapdown = false;
    },
    getCampaigns() {
      Campaign.getCampaigns(this.filterModel).then(res => {
        if (res && res.data && res.data.Data) {
          this.campaigns = res.data.Data.campaigns;
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.content-row {
  height: unset;
  padding-left: 20px;
  position: relative;
}
.content-layout-fixer {
  position: relative;
}
.gray-bg {
  background: palette-color-level('grey', '10');
  margin-bottom: 60px;
  padding: 17px 0;
}
/deep/ .crumbs-holder {
  padding-left: 0;
}
ul.filter-and-search-btn {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0;
  margin: 0;
  list-style-type: none;
  li.search-wrapper {
    width: 432px;
    border: none;
    position: relative;
    input {
      width: 100%;
      border: solid 1px #b4c2d3;
      border-radius: 4px;
      -webkit-border-radius: 4px;
      -moz-border-radius: 4px;
      height: 44px;
      line-height: 44px;
      font-size: 14px;
      letter-spacing: normal;
      color: #79838e;
      padding: 0 15px;
    }
    .search-icon {
      position: absolute;
      right: 20px;
    }
  }
  li {
    float: left;
    border: solid 1px #b4c2d3;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    width: 44px;
    height: 44px;
    margin-left: 10px;
    cursor: pointer;
    svg {
      position: relative;
      top: 12px;
      left: 12px;
    }
  }
}
.campaigns-wrapper {
  position: relative;
  width: 100%;
  padding: palette-space-level(10) palette-space-level(20);
  margin-bottom: palette-space-level(30);
  &-header {
    margin-bottom: palette-space-level(10);
  }
  .filters-wrapper {
    position: absolute;
    z-index: 9;
    width: 100%;
    margin-top: 10px;
    padding: 30px 0;
    background: palette-color-level('white', '100');
    border: solid 1px palette-color-level('grey', '20');
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    .filter-row {
      display: inline-block;
      width: 100%;
      .filter-column {
        float: left;
        width: 46%;
        margin-right: 4%;
        overflow-y: auto;
        .filter-list {
          max-height: 250px;
          overflow-y: auto;
          border-top: solid 1px rgba(180, 194, 211, 0.5);
        }
        &:nth-child(2) {
          margin-right: 0;
        }
        &:nth-child(1),
        &:nth-child(2) {
          .single-list-item {
            cursor: pointer;
            /deep/ span {
              padding-left: 30px;
            }
          }
        }
        .filter-title {
          margin-bottom: 20px;
          margin-left: 30px;
        }
      }
    }
    .filter-apply-btn {
      display: block;
      width: 208px;
      height: 62px;
      margin: 60px auto 0;
      text-align: center;
      line-height: 62px;
      text-decoration: none;
      border-radius: 30px;
      -webkit-border-radius: 30px;
      -moz-border-radius: 30px;
      border: solid 1px palette-color-level('grey', '20');
      font-size: 16px;
      font-weight: 600;
      color: palette-color-level('grey', '50');
      cursor: pointer;
    }
  }
}
</style>
