<template>
  <div class="content-layout-fixer">
    <div class="content-card-list">
      <ul class="list-container">
        <li v-for="content in contents" class="content-card" :key="content.campaignId">
          <BrandCampaignItem :model="content"></BrandCampaignItem>
        </li>
      </ul>
      <button v-if="showButton" class="btn-more" @click="showMoreClicked(title)">
        <VueText sizeLevel="4" weightLevel="4">Daha Fazla Gör</VueText>
      </button>
    </div>
  </div>
</template>
<script>
import BrandCampaignItem from '@/components/brand/BrandCampaignItem/BrandCampaignItem.vue';
import StorageHelper from '@/utils/storageHelper';
import VueText from '@/components/shared/VueText/VueText.vue';
export default {
  name: 'BrandCampaignList',
  components: {
    VueText,
    BrandCampaignItem,
  },
  props: {
    contents: {
      type: Array,
    },
    showButton: {
      type: Boolean,
    },
  },
  methods: {
    showMoreClicked(title) {
      this.$emit('showMoreClicked', title);
    },
  },
  beforeMount() {
    const storageUser = new StorageHelper({ id: process.env.VUE_APP_STORAGE_CURRENT_USER_KEY });
    let name = '';
    if (storageUser) {
      name = storageUser.get().name;
    }
    this.$route.meta.title = this.$route.meta.title + name;
  },
};
</script>
<style scoped lang="scss">
.content-card-list {
  margin: palette-space-level(20) 0;
  display: flex;
  flex-direction: column;
  .head-title {
    margin-bottom: palette-space-level(30);
  }
  .btn-more {
    margin-top: palette-space-level(30);
    border: 1px solid palette-color-level('grey', '20');
    align-self: center;
    background-color: palette-color-level('white', '100');
    border-radius: palette-radius-level(15);
    padding: palette-space-level(20) palette-space-level(50);
  }
  .list-container {
    display: grid;
    grid-template-columns: repeat(3, 321px);
    grid-gap: 20px;
    list-style: none;

    .content-card {
      border: 1px solid palette-color('grey-20');

      .media-box {
        position: relative;
        img {
          width: 100%;
        }
        .media-content {
          position: absolute;
          width: 100%;
          display: flex;
          align-items: center;
          flex-direction: row;
          justify-content: space-between;
          bottom: 10px;
          padding: 0 palette-space-level(10);
          .media-category {
            padding: 10px;
            box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.5);
            font-size: 12px;
            font-weight: 600;
          }
        }
      }
      .feed-content {
        padding: palette-space-level(20);

        p {
          margin-bottom: 5px;
        }
      }
    }
  }
}
</style>
