<template>
  <div class="campaign-item-container" @click="campaignDetailClick()">
    <BrandMediaContentBox
      class="campaign-media"
      :aspectRatios="propData.media.aspect.by_16_9"
      :as="propData.media.type.image"
      :mediaSrc="modelData.imageUrl"
    >
    </BrandMediaContentBox>
    <div class="campaign-item-summary">
      <VueText sizeLevel="4" class="campaign-item-content">{{
        modelData.shortDescription
      }}</VueText>
    </div>
    <BrandButton
      as="router-link"
      class="click-btn"
      :target="{
        name: detailRoute,
        params: { id: modelData.campaignId },
      }"
    >
      <VueText color="white-100" sizeLevel="4" weightLevel="3">HEMEN TIKLA</VueText>
    </BrandButton>
  </div>
</template>
<script>
import MEDIA_CONSTANTS from '@/constants/media.constants.js';
import VueText from '@/components/shared/VueText/VueText.vue';
import BrandButton from '@/components/brand/BrandButton/BrandButton.vue';
import BrandMediaContentBox from '@/components/brand/BrandMediaContentBox/BrandMediaContentBox.vue';
import { COMPONENT_SIZES } from '@/constants/component.constants.js';
import RoutesSecure from '@/router/routes/RoutesSecure';
import gtmUtils from '@/mixins/gtmUtils.js';

export default {
  name: 'CampaignItem',
  props: {
    model: {
      type: Object,
      required: true,
    },
  },
  mixins: [gtmUtils],
  components: {
    VueText,
    BrandMediaContentBox,
    BrandButton,
  },
  data() {
    return {
      modelData: Object.assign({}, this.model),
      detailRoute: RoutesSecure.CampaignDetail.name,
    };
  },
  computed: {
    sizes() {
      return COMPONENT_SIZES;
    },
    propData() {
      const media = {
        aspect: MEDIA_CONSTANTS.MEDIA_ASPECT_RATIOS,
        type: MEDIA_CONSTANTS.MEDIA_CONTENT_TYPES,
      };
      return { media };
    },
  },
  methods: {
    campaignDetailClick() {
      this.pushDataLayerEvent('ecommerce');
      this.pushDataLayerEvent('kampanyalar', {
        name: this.modelData.shortDescription,
      });
      this.$router.push({
        name: this.detailRoute,
        params: { id: this.modelData.campaignId },
      });
    },
  },
};
</script>
<style scoped lang="scss">
.campaign-media {
  position: relative;
  display: flex;
  width: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  align-items: center;
  overflow: hidden;
  height: 100%;

  &:before {
    content: '';
    display: block;
  }

  iframe {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
.click-btn {
  max-width: 130px;
  border-radius: 50%;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  margin-top: 10px;
  margin-left: 20px;
  padding: 10px 30px;
}
.campaign-info {
  padding: 10px;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.campaign-item-container {
  margin-bottom: 45px;
  .campaign-category {
    font-size: 12px;
    padding: 10px 20px;
    -webkit-box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.5);
    box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.5);
    max-width: 200px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .campaign-item-summary {
    padding: 20px 20px 0;

    .campaign-item-title,
    .campaign-item-content {
      color: $brand-link-primary-hover-color;
    }
  }
}
</style>
